import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { accountLoginRoutine } from 'state/account/account.routines';
import { accountStateSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { Login, LoginRequestErrorPayload } from 'types/login';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import SignInForm, {
    SignInLockedFailureModalContent,
    SignInNewPasswordRequiredModalContent,
    SignInUnknownFailureModalContent
} from 'components/sign-in-form/sign-in-form.component';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';
import PageBlocks from 'components/page-blocks/page-blocks';
import { TFunction } from 'i18next';

import storageHelper from 'util/storageHelper';

const dynamicGreeting = (translation: TFunction): string => {
    const hour = new Date().getHours();
    if (hour >= 0 && hour < 12) {
        return translation('signIn.labels.title.morning');
    } else if (hour >= 12 && hour < 17) {
        return translation('signIn.labels.title.afternoon');
    }
    return translation('signIn.labels.title.evening');
};

const setSessionStorage = (login: Login): void => {
    if (login.rememberMe === true) {
        storageHelper.session.setRememberMe(login.email, String(login.rememberMe));
    } else {
        storageHelper.session.removeRememberMe();
    }
};

const SignIn = ({ location, data }: { location: any; data: GatsbyTypes.SignInPageDataQuery }): ReactElement => {
    const { t } = useTranslation();
    const { isLoggingIn } = useSelector(accountStateSelector);
    const dispatch = useDispatch();

    const onSubmitSignInForm: (login: Login) => void = (login) => {
        dispatch(
            accountLoginRoutine.trigger({
                login,
                onSuccess: () => {
                    // Moved the redirect logic into account.sagas.ts, since the get-started page
                    // also triggers the accountLoginRoutine.
                    setSessionStorage(login);
                },
                onFailure: (err: LoginRequestErrorPayload) => {
                    if (err && err.error === 'user_locked') {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <>
                                        <SignInLockedFailureModalContent translation={t} />
                                        <PageBlocks
                                            pageLocation={location.pathname}
                                            dataGALocation={'Signin'}
                                            modalBlock={true}
                                        />
                                    </>
                                ),
                                ctas: [
                                    {
                                        label: t('modals.signInLockedFailure.submit'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                            navigate('/');
                                        },
                                        dataGALocation: 'SigninLockedError'
                                    }
                                ]
                            })
                        );
                    } else if (err && err.error === 'reset_password_required') {
                        dispatch(
                            openModal({
                                showClose: true,
                                size: 'lg',
                                type: 'danger',
                                headerContent: (
                                    <BirdiModalHeaderDanger
                                        headerText={t('modals.signInNewPasswordRequired.title')}
                                        icon="alert"
                                    />
                                ),
                                bodyContent: (
                                    <>
                                        <SignInNewPasswordRequiredModalContent />
                                    </>
                                ),
                                ctas: [
                                    {
                                        label: t('modals.signInNewPasswordRequired.submit'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                            navigate('/');
                                        },
                                        dataGALocation: 'SigninUnknownError'
                                    }
                                ]
                            })
                        );
                    } else {
                        dispatch(
                            openModal({
                                showClose: true,
                                size: 'lg',
                                type: 'danger',
                                headerContent: (
                                    <BirdiModalHeaderDanger
                                        headerText={t('modals.signInUnknownFailure.header')}
                                        icon="alert"
                                    />
                                ),
                                bodyContent: (
                                    <>
                                        <SignInUnknownFailureModalContent translation={t} body={err.description} />
                                        <PageBlocks
                                            pageLocation={location.pathname}
                                            dataGALocation={'Signin'}
                                            modalBlock={true}
                                        />
                                    </>
                                ),
                                ctas: [
                                    {
                                        label: t('modals.signInUnknownFailure.submit'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'SigninUnknownError'
                                    }
                                ]
                            })
                        );
                    }
                }
            })
        );
    };
    const showSuccessfulPasswordChangeModal = React.useCallback(() => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContent
                        body={t('signIn.resetPasswordModal.body')}
                        icon="default"
                        title={t('signIn.resetPasswordModal.title')}
                    />
                ),
                ctas: [
                    {
                        dataGALocation: 'PasswordChangeRequest',
                        label: t('signIn.resetPasswordModal.submit'),
                        onClick: () => {
                            dispatch(closeModal({}));
                            return false;
                        },
                        variant: 'primary'
                    }
                ],
                showClose: true
            })
        );
    }, [dispatch, t]);

    // The successful password change modal should be displayed just once.
    // Implementing a use effect with empty dependencies to avoid calling
    // this function when the dependencies are updated.
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        if (urlParams.get('success')) {
            showSuccessfulPasswordChangeModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WorkflowLayout
            backgroundImage={data.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('signIn.labels.eyebrowText') }}
            anonymousOnly={true}
        >
            <WorkflowLayoutFormWrapper eyebrowText={t('signIn.labels.eyebrowText')} title={dynamicGreeting(t)}>
                <br />
                <SignInForm onSubmit={onSubmitSignInForm} isLoggingIn={isLoggingIn} />
                <br />
                <div className="mx-0 mx-lg-5">
                    <PageBlocks pageLocation={location.pathname} dataGALocation={'Signin'} />
                </div>
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default SignIn;

export const query = graphql`
    query SignInPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/bird-on-white-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
