import React, { ReactElement, useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import { LOGIN_SCHEMA } from 'schema/login.schema';
import { Login } from 'types/login';

import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import Button from 'ui-kit/button/button';
import Link from 'ui-kit/link/link';
import Text from 'ui-kit/text/text';
import PasswordText from 'ui-kit/password-text/password-text';

import { SignInFormProps } from './sign-in-form.props';
import { Checkbox } from 'ui-kit/checkbox';
import storageHelper from 'util/storageHelper';
import sanitizeHtml from 'sanitize-html';

import './sign-in-form.style.scss';

export const SignInNewPasswordRequiredModalContent = () => {
    const { t } = useTranslation();
    return (
        <>
            <BirdiModalContentAlt subTitle={t('modals.signInNewPasswordRequired.subtitle')} />
            <div className="highlight-text-container">
                <div className="highlight-text">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(t('modals.signInNewPasswordRequired.additionalInformation'), {
                                allowedTags: ['strong']
                            })
                        }}
                    ></p>
                </div>
            </div>
        </>
    );
};

export const SignInUnknownFailureModalContent = ({
    body,
    translation
}: {
    body?: string;
    translation: TFunction<'translation'>;
}) => {
    return (
        <BirdiModalContentAlt
            title={translation('modals.signInUnknownFailure.title')}
            subTitle={translation('modals.signInUnknownFailure.subtitle')}
        />
    );
};

export const SignInLockedFailureModalContent = ({ translation }: { translation: TFunction<'translation'> }) => {
    return (
        <BirdiModalContent
            icon="default"
            title={translation('modals.signInLockedFailure.title')}
            body={translation('modals.signInLockedFailure.body')}
            footnote={translation('modals.signInFailure')}
        />
    );
};

const SignInForm = ({ isLoggingIn, onSubmit, style }: SignInFormProps): ReactElement => {
    const { t } = useTranslation();
    const formName = 'SignInForm';

    const [rememberMeValue, setRememberMeValue] = useState<boolean>(storageHelper.session.getRememberMeCheckBox());

    const handleSubmit = useCallback(
        (values: Partial<Login>) => {
            onSubmit(values as Login);
        },
        [onSubmit]
    );
    return (
        <Container fluid style={style}>
            <Row>
                <Col>
                    <Formik<Partial<Login>>
                        onSubmit={handleSubmit}
                        validationSchema={LOGIN_SCHEMA}
                        initialValues={{
                            email: '',
                            password: '',
                            acceptedTos: undefined
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="sign-in-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Text
                                    label={t('signIn.labels.email')}
                                    name="email"
                                    maxLength={255}
                                    onChange={formik.handleChange}
                                    errors={formik.errors?.email ? t('signIn.errors.email') : undefined}
                                    touched={formik.touched.email}
                                    value={formik.values?.email}
                                    defaultValue={
                                        (formik.initialValues.email = storageHelper.session.getRememberMeEmail(
                                            formik.values?.email
                                        ))
                                    }
                                />
                                <PasswordText
                                    label={t('signIn.labels.password')}
                                    name="password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    errors={formik.errors?.password ? t('signIn.errors.password') : undefined}
                                    touched={formik.touched.password}
                                    value={formik.values?.password}
                                    autocomplete="current-password"
                                />

                                <Row>
                                    <Col className="text-center">
                                        <Checkbox
                                            label={t('signIn.labels.rememberMe')}
                                            id="rememberMe"
                                            name="rememberMe"
                                            onClick={(isChecked: boolean) => {}}
                                            onCheckChanged={(isChecked: boolean) => {
                                                setRememberMeValue(isChecked);
                                                formik.setFieldValue('rememberMe', isChecked);
                                            }}
                                            defaultValue={rememberMeValue}
                                        />
                                    </Col>
                                    <Col className="text-right">
                                        <Link
                                            to="/login-issues"
                                            label={t('signIn.labels.loginIssues')}
                                            variant="subtle"
                                            dataGALocation={'Signin'}
                                        />
                                    </Col>
                                </Row>

                                <div className="d-flex flex-column align-items-center">
                                    <Button
                                        async
                                        className="mt-4 sm-full"
                                        label={t('signIn.labels.submit')}
                                        type="submit"
                                        isBusy={isLoggingIn}
                                        dataGAFormName={formName}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default SignInForm;
